import { useEffect, type FC, type PropsWithChildren } from 'react';
import { useNavigate } from 'react-router';

import useRouteHasFeatureFlag from 'common/libs/utils/featureFlags/useRouteHasFeatureFlag';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type -- intentional
export interface FeatureFlagGuardProps {}

const FeatureFlagGuard: FC<PropsWithChildren<FeatureFlagGuardProps>> = ({ children }) => {
  const { match, enabled } = useRouteHasFeatureFlag();

  const navigate = useNavigate();

  useEffect(() => {
    if (match?.route.featureFlag && !enabled) {
      navigate('/404', { replace: true });
    }
  }, [match?.route.featureFlag, enabled, navigate]);

  return children;
};

export default FeatureFlagGuard;
