import { StrictMode, useEffect, type ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router';

import useAuthentication from 'common/states/auth/useAuthentication';
import { LOGIN_ROUTE_PATHNAME } from 'common/routes';

export interface KeycloakProviderProps {
  children: ReactNode;
}

const KEY_NAVIGATE_BACK_TO = `navigate-back-to`;
const DEFAULT_ROUTE_PATHNAME = `../studio`;

export const AuthenticationProvider = ({ children }: KeycloakProviderProps) => {
  const {
    actions: { initialize },
    states: { loading, isAuthenticated, isInitialized },
  } = useAuthentication();

  const navigate = useNavigate();
  const location = useLocation();

  const onLoginPage = () => {
    if (isAuthenticated) {
      const navigateBackToUrl = window.localStorage.getItem(KEY_NAVIGATE_BACK_TO);
      window.localStorage.removeItem(KEY_NAVIGATE_BACK_TO);
      navigate(navigateBackToUrl ?? DEFAULT_ROUTE_PATHNAME);
    }
  };

  const onNonLoginPage = () => {
    if (!isAuthenticated) {
      window.localStorage.setItem(KEY_NAVIGATE_BACK_TO, window.location.pathname);
      window.location.assign(LOGIN_ROUTE_PATHNAME);
    }
  };

  useEffect(() => {
    if (!isInitialized) {
      initialize();
    }
  }, []);

  useEffect(() => {
    if (loading || !isInitialized) return;

    if (location.pathname === LOGIN_ROUTE_PATHNAME) {
      onLoginPage();
    } else {
      onNonLoginPage();
    }
  }, [isAuthenticated, isInitialized, loading]);

  if (loading) return <></>;

  // The StrictMode causes the useEffect to be executed twice,
  // which is not well handled by keycloak library and causes UI to enter an infinite loop of refreshing.
  // That's why we need to keep it at this level
  return <StrictMode>{children}</StrictMode>;
};

export default AuthenticationProvider;
