/**
 * Serves as a static record of all keys used. This record should be kept up to date for parity with optimizely
 * https://app.optimizely.com/v2/projects/28794020046/flags/list
 */

/**
 * Rollout flags are for temporary features
 * When you're rolling out a new feature or an update, you might not want to release it to all users at once.
 * Often times we will perform abTesting to see what side performs better.
 */
export const rolloutFlags = [
  'rollout-test-for-storybook', // only used in storybook
  'rollout-dashboard-charts',
  'rollout-show-reporting',
  'rollout-show-studio-recommendations',
  'rollout-show-lite-recommendations',
  'rollout-show-jarvis-insight-uncaptured',
  'rollout-show-jarvis-insight-missed',
  'rollout-show-jarvis-insight-growth',
  'rollout-show-jarvis-insight-upcoming',
  'rollout-show-performance-insight-revenue',
  'rollout-show-performance-insight-offers',
  'rollout-show-performance-insight-acceptance',
  'rollout-show-performance-insight-upgraded',
] as const;

export type FeatureFlagName = (typeof rolloutFlags)[number];

export const rolloutExpiryDates = {
  THREE_MONTHS_FROM_MVP: '2025-07-01',
};

/**
 * Personalization flags are permanent features
 * Often times, we want to tailor a personalized experience for the user based on their demographic, device type, points amount...
 * This is very individual, per requestId in our case.
 * Mind you, customization is a little different and will be done with the config file as its more global and often partner specific.
 */
export const personalizationFlags = [
  // 'personalization-...',
] as const;

const allFlags = [...rolloutFlags, ...personalizationFlags];

export default allFlags;
