import { Outlet, type RouteObject } from 'react-router';
import { ApolloProvider } from '@apollo/client';
import { DashboardCustomizeOutlined, VerifiedOutlined, InsertChartOutlined } from '@mui/icons-material';

import client from '../common/configs/graphqlClientConfig';

import AuthenticationProvider from 'common/libs/providers/AuthenticationProvider';
import PartnerConfigProvider from 'common/libs/providers/PartnerConfigProvider';
import FeatureFlagsProvider from 'common/libs/providers/FeatureFlagsProvider';

import type { NavigationAction } from 'types/NavigationAction';
import SnackbarProvider from './libs/providers/SnackbarProvider';
import { Permission } from 'types/Permissions';
import PermissionGuard from './libs/providers/PartnerConfigProvider/PermissionGuard';
import InactiveTimeoutGuard from './libs/providers/PartnerConfigProvider/InactiveTimeoutGuard';
import FeatureFlagGuard from './libs/providers/PartnerConfigProvider/FeatureFlagGuard';
import type { FeatureFlagName } from './libs/utils/featureFlags/flags';
import { rolloutExpiryDates } from './libs/utils/featureFlags/flags';

export const AuthenticatedLayout = () => (
  <AuthenticationProvider>
    <ApolloProvider client={client}>
      <PartnerConfigProvider>
        <FeatureFlagsProvider>
          <PermissionGuard>
            <FeatureFlagGuard>
              <SnackbarProvider>
                <InactiveTimeoutGuard>
                  <Outlet />
                </InactiveTimeoutGuard>
              </SnackbarProvider>
            </FeatureFlagGuard>
          </PermissionGuard>
        </FeatureFlagsProvider>
      </PartnerConfigProvider>
    </ApolloProvider>
  </AuthenticationProvider>
);

interface FeatureFlag {
  flagName: FeatureFlagName;
  expiryDate: string;
}

export type ExtendedRouteObject = RouteObject & NavigationAction;
export type ExtendedDataRouteObject = ExtendedRouteObject & {
  id: string;
  children?: ExtendedDataRouteObject[];
  permission?: Permission;
  hideFromNavigation?: boolean;
  featureFlag?: FeatureFlag;
};

const getMVPFeatureFlag = (flagName: FeatureFlagName): FeatureFlag => {
  return { flagName, expiryDate: rolloutExpiryDates.THREE_MONTHS_FROM_MVP };
};

export const liteRoutes: ExtendedDataRouteObject[] = [
  {
    id: 'Reports',
    path: 'ami/:pid?/reports/:reportId?',
    lazy: async () => await import('../lite/routes/LiteReportsRoute'),
    icon: <InsertChartOutlined />,
    permission: Permission.viewReports,
    featureFlag: getMVPFeatureFlag('rollout-show-reporting'),
  },
  {
    id: 'Recommendations',
    path: 'ami/:pid?/recommendations',
    lazy: async () => await import('../lite/routes/LiteRecommendationsRoute'),
    icon: <VerifiedOutlined />,
    featureFlag: getMVPFeatureFlag('rollout-show-lite-recommendations'),
  },
  {
    id: 'Dashboard',
    path: 'ami/:pid?',
    lazy: async () => await import('../lite/routes/LiteHomeRoute'),
    icon: <DashboardCustomizeOutlined />,
  },
];

export const studioRoutes: ExtendedDataRouteObject[] = [
  {
    id: 'StudioReports',
    path: 'studio/:pid?/reports/:reportId?',
    lazy: async () => await import('../studio/routes/StudioReportsRoute'),
    icon: <InsertChartOutlined />,
    permission: Permission.viewReports,
    featureFlag: getMVPFeatureFlag('rollout-show-reporting'),
  },
  {
    id: 'StudioRecommendations',
    path: 'studio/:pid?/recommendations',
    lazy: async () => await import('../studio/routes/StudioRecommendationsRoute'),
    icon: <VerifiedOutlined />,
    featureFlag: getMVPFeatureFlag('rollout-show-studio-recommendations'),
  },
  {
    id: 'StudioDashboard',
    path: 'studio/:pid?',
    lazy: async () => await import('../studio/routes/StudioDashboardRoute'),
    icon: <DashboardCustomizeOutlined />,
  },
];

// for now both studio and lite inherit the same 404 route
const fallback = { id: '404', path: '*', lazy: async () => await import('../lite/routes/NotFoundRoute') };

export const LOGIN_ROUTE_PATHNAME = `/`;
/**
 * lazy loading each route to avoid the need for webpack-module-federation
 * note: This feature only works if using a data router -> https://reactrouter.com/en/main/route/lazy.
 *        `createBrowserRouter()` vs. `<BrowserRouter>`
 */
export const routes: ExtendedDataRouteObject[] = [
  {
    id: 'Login',
    path: LOGIN_ROUTE_PATHNAME,
    lazy: async () => await import('../login/routes/LoginRoute'),
  },
  {
    id: 'App',
    path: '*',
    element: <AuthenticatedLayout />,
    children: [...liteRoutes, ...studioRoutes, fallback],
  },
];

export default routes;
