import useGoogleAnalytics from './private/useGoogleAnalytics';
import type { PartnerInfo } from 'types/Configuration';

const useCommmonEvents = () => {
  const publisher = useGoogleAnalytics();

  const publishClick = (text: string) => {
    void publisher.actions.publishClick(text);
  };

  const publishChangePartner = (partner: PartnerInfo) => {
    void publisher.actions.publishChangePartner(partner);
  };

  const publishError = (component: string, error: Error) => {
    void publisher.actions.publishError(component, error);
  };

  return {
    actions: {
      publishClick,
      publishChangePartner,
      publishError,
    },
  };
};

export default useCommmonEvents;
