import { useContext } from 'react';
import { matchRoutes } from 'react-router';
import { OptimizelyContext, type OptimizelyDecision } from '@optimizely/react-sdk';

import routes from 'common/routes';

const useRouteHasFeatureFlag = () => {
  const match = matchRoutes(routes, location.pathname)?.at(-1);
  const flagName = match?.route.featureFlag?.flagName;

  const { optimizely } = useContext(OptimizelyContext);

  const { enabled }: Partial<OptimizelyDecision> =
    optimizely && flagName ? optimizely.decide(flagName) : { enabled: true }; // unlike useRollout, we fallback to true here to allow for routes to leave the flagName undefined

  return {
    match,
    enabled,
  };
};

export default useRouteHasFeatureFlag;
