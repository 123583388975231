import {
  GoogleAnalyticsEventType,
  GoogleAnalyticsReporting,
  GoogleAnalyticClick,
  type GoogleAnalyticPage,
  type GoogleAnalyticsPerformance,
} from 'types/analytics/GoogleAnalyticsEvent';
import type { GenerateReportInfo } from '../../../../../studio/routes/StudioReportsRoute/components/GenerateReport';
import usePublishGoogleAnalyticsEventMutation from './usePublishGoogleAnalyticsEventMutation';

import useAppConfig from 'common/states/config/useAppConfig';
import useCurrentLanguage from 'common/states/language/useCurrentLanguage';
import useCurrentCurrency from 'common/states/currency/useCurrentCurrency';
import useAuthentication from 'common/states/auth/useAuthentication';
import type { PartnerInfo } from 'types/Configuration';

/**
 * This hook should ONLY be used in analytics folder
 * We want to hide the events implementation (GA + Quantify) under the useXXXEvents.ts
 */
const useGoogleAnalytics = () => {
  const publisher = usePublishGoogleAnalyticsEventMutation();
  const { config, firstCompleteRender } = useAppConfig().states;
  const { currentCurrency } = useCurrentCurrency().states;
  const { currentLanguage } = useCurrentLanguage().states;
  const { authenticatedUser } = useAuthentication().states;

  const publishPageView = async (page: GoogleAnalyticPage) => {
    publisher.actions.publish(GoogleAnalyticsEventType.pageView, {
      page_params: {
        location: window.location.href,
        title: page,
      },
    });
  };

  const publishPartialPageView = async (page: GoogleAnalyticPage) => {
    publisher.actions.publish(GoogleAnalyticsEventType.pageViewPartial, {
      page_params: {
        location: window.location.href,
        title: page,
      },
    });
  };

  /** should be only fired once at the first load of the app */
  const publishDefaultLayer = async () => {
    const authenticatedUserIsPGUser = authenticatedUser?.email?.includes('@plusgrade');

    if (firstCompleteRender) {
      publisher.actions.publish(GoogleAnalyticsEventType.default, {
        currency_code: currentCurrency.code,
        language: currentLanguage.code,
        keycloak_user_id: authenticatedUser?.id,
        partner_name: config?.companyCode,
        partner_type: config?.partnerType,
        is_internal_user: authenticatedUserIsPGUser,
      });
    }
  };

  const publishClick = async (text: string) => {
    publisher.actions.publish(GoogleAnalyticsEventType.click, { event_params: { click_text: text } });
  };

  const publishPageLoadPerformance = async (googleAnalyticsPerformance: GoogleAnalyticsPerformance) => {
    try {
      const elapsedTime = performance.now();
      if (!sessionStorage.getItem(googleAnalyticsPerformance)) {
        publisher.actions.publish(GoogleAnalyticsEventType.performance, {
          event_params: { performance_name: googleAnalyticsPerformance, value: elapsedTime },
        });
        sessionStorage.setItem(googleAnalyticsPerformance, 'sent');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const publishGenerateReport = async (report: GenerateReportInfo) => {
    publisher.actions.publish(GoogleAnalyticsReporting.generateReport, {
      event_params: {
        type: report.type,
        start_date: report.dateRange.startDate,
        end_date: report.dateRange.endDate,
        based_on: report.basedOn,
        upgrade_type: report.upgradeType,
        flight_number: report.flightNumber,
        origin: report.origin,
        destination: report.destination,
        include_pii: report.includePII,
      },
    });
  };

  const publishDownloadReport = async () => {
    publisher.actions.publish(GoogleAnalyticsReporting.downloadReport, {
      event_params: {},
    });
  };

  const publishChangePartner = async (partner: PartnerInfo) => {
    publisher.actions.publish(GoogleAnalyticClick.changePartner, {
      partner_name: partner.companyCode,
      partner_type: partner.partnerType,
    });
  };

  const publishError = async (component: string, error: Error) => {
    publisher.actions.publish(GoogleAnalyticsEventType.error, {
      event_params: {
        error_source: component,
        error_message: error.message,
      },
    });
  };

  return {
    actions: {
      publishPageView,
      publishPartialPageView,
      publishDefaultLayer,
      publishClick,
      publishPageLoadPerformance,
      publishChangePartner,
      publishGenerateReport,
      publishDownloadReport,
      publishError,
    },
  };
};

export default useGoogleAnalytics;
