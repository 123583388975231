import { Fragment, type Key, type ReactNode } from 'react';
import { makeVar, useReactiveVar } from '@apollo/client';
import deepEqual from 'deep-equal';
import htmlParse from 'html-react-parser';

import type { AppName } from 'types/AppName';
import type Currency from 'types/Currency';
import type { Recommendation, VariantTextKey } from 'types/Recommendation';
import translationFiles from './translations';
import {
  printMissingTextkey,
  detectHtml,
  splitByArgument,
  shouldRenderDebugTextKeys,
  renderDebugTextKey,
  renderStyledDebugTextKey,
} from './utils';

export interface TextKey {
  code: string;
  text: string;
  pid: string;
}

export interface RawTextKeyResponse {
  collectionId: string;
  messages: TextKey[];
}

export interface Translation {
  initialized: boolean;
  loading: boolean;
  error: boolean;
  textKeys: Record<string, string>;
  pid: string;
}

export type Translate = (code: TextKeyCode, ...args: (string | number | ReactNode)[]) => string;

const initialState: Translation = {
  initialized: false,
  loading: false,
  textKeys: {},
  pid: '',
  error: false,
};

const translation = makeVar<Translation>(initialState);

export const setTranslation = (newTextKeys: Partial<Translation>) => {
  const currentTextkeys = translation();
  if (!deepEqual(currentTextkeys, newTextKeys)) {
    translation({
      ...currentTextkeys,
      ...newTextKeys,
    });
  }
};

/**
 * Should be used directly only for tests purposes. Use the hook otherwise
 */
export const resetTranslation = () => {
  translation(initialState);
};

export const fetchTextkeys = async (pid: string, lang: keyof typeof translationFiles) => {
  try {
    setTranslation({ loading: true, pid });

    const { default: textKeyResult } = await translationFiles[lang]();

    setTranslation({
      initialized: true,
      loading: false,
      error: false,
      textKeys: textKeyResult,
    });
  } catch (error) {
    console.error(error);
    setTranslation({ loading: false, error: true });
  }
};

const findTextKey = (code: string) => {
  const foundTranslation = translation().textKeys[`${code}`];
  return typeof foundTranslation === 'string' ? foundTranslation : code;
};

const replacePlaceholders = (text: string, args: Array<string | number>): string =>
  text.replace(/{(\d+)}/g, (placeholder, index) => args[index].toString() ?? placeholder);

const logMissingTextkeys = (text: string, code: string) => {
  const codeNotInitialized = code.includes('..'); // happens when the text group is not fetched yet

  if (translation().initialized && !codeNotInitialized && text === code) {
    printMissingTextkey('[TRANSLATION] missing text key', code);
  }
};

const renderHtml = (result: string) => (detectHtml(result) ? (htmlParse(result) as string) : result);
const renderIfNecessary = (arg: ReactNode | string) => (typeof arg === 'string' ? renderHtml(arg) : arg);
const replaceStyledPlaceholders = (text: string, args: Array<ReactNode | string>) => (
  <>
    {splitByArgument(text).map((part: string, index: Key | null | undefined) => (
      <Fragment key={index}>{typeof part === 'number' ? renderIfNecessary(args[part]) : renderHtml(part)}</Fragment>
    ))}
  </>
);

const translate: Translate = (code: TextKeyCode, text: string, ...args: Array<string | number>) => {
  // if key suppression is active, return only the code with no translations + list of args joined by a ;
  if (shouldRenderDebugTextKeys()) {
    return renderDebugTextKey(code, args);
  }

  const result = args.length > 0 ? replacePlaceholders(text, args) : text;

  logMissingTextkeys(text, code);

  return renderHtml(result);
};

const translateWithJsxArgs: Translate = (code: TextKeyCode, text: string, ...args: Array<ReactNode>) => {
  // if key suppression is active, return only the code with no translations + list of args joined by a ;
  if (shouldRenderDebugTextKeys()) {
    return renderStyledDebugTextKey(code, args);
  }
  logMissingTextkeys(text, code);

  const result = args.length > 0 ? replaceStyledPlaceholders(text, args) : text;

  return result as string;
};

const translateTextKeys = (code: TextKeyCode, text: string, ...args: Array<string | number | ReactNode>) => {
  if (args.every((arg) => typeof arg === 'string' || typeof arg === 'number')) {
    return translate(code, text, ...(args as string[]));
  }
  return translateWithJsxArgs(code, text, ...args);
};
const t: Translate = (code: TextKeyCode, ...args: Array<string | number | ReactNode>) => {
  const text = findTextKey(code);
  return translateTextKeys(code, text, ...args);
};

const useTranslation = () => {
  const reactiveTranslation = useReactiveVar(translation);

  return {
    actions: {
      t,
    },
    states: reactiveTranslation,
  };
};

export default useTranslation;

// eslint-disable-next-line @typescript-eslint/no-empty-object-type -- for parity with canvas and it's buggy dts-loader
export interface AllKeys extends TextKeys {}
export type TextKeyCode = keyof Record<keyof AllKeys, string>;

/**
 * all textkeys should be defined following the pattern:
 * [key]: string // [<folders they are used>] // <default english translation>
 */
export interface TextKeys {
  'default.page.title': string; // AMI Suite
  'default.page.description': string; // AMI Suite

  [key: `common.navigation.label.${string}`]: string; // Dashboard

  'common.language.en': string; // English
  'common.language.fr': string; // French
  'common.language.gr': string; // Greek

  'common.drawer.home': string; // Go to homepage
  'common.drawer.logo': string; // Plusgrade logo
  'common.drawer.expand': string; // Expand
  'common.drawer.collapse': string; // Collapse

  'common.partner-selector.logo': string; // {0} brand logo
  'common.partner-selector.button-label': string; // Open partner selector
  'common.partner-selector.popover.label': string; // Select organization
  'common.partner-selector.popover.recents-title': string; // Recents

  'common.user-modal.button-label': string; // Open user modal
  'common.user-modal.logout': string; // Logout {0}
  'common.user-modal.popover.label': string; // Account

  'common.session.timeout.dialog-title': string; // Automatic Log Out
  'common.session.timeout.inactive-text': string; // You have been inactive for the past {0} minutes.
  'common.session.timeout.logout-text': string; // You will be logged out in {0} seconds unless you choose to stay logged in.
  'common.session.timeout.stay-in.button-label': string; // Stay Logged in

  'common.date-range-input.start-label': string; // Start date
  'common.date-range-input.end-label': string; // End date
  [key: `common.date-range-input.radio.${string}`]: string; // Custom, Last 7 days, Last 30 days
  'common.date-range-input.error.select-date-range': string; // Select a date range

  'common.feed-tabs.proposed': string; // Proposed
  'common.feed-tabs.accepted': string; // Accepted
  'common.feed-tabs.declined': string; // Declined

  'common.time-scale.label': string; // Date Range Filter
  'common.time-scale.last7days': string; // Last 7 Days
  'common.time-scale.last30days': string; // Last 30 Days
  'common.time-scale.lastyear': string; // Last Year

  [key: `common.input.${string}.label`]: string; // Email

  'common.recommendation.delta-increased': string; // Increase
  'common.recommendation.delta-decreased': string; // Decrease
  'common.recommendation.accept': string; // Accept
  'common.recommendation.decline': string; // Decline
  'common.recommendation.origin-to-destination': string; // {0} {1} {2}
  'common.recommendation.pricing-copy': string; // {0} pricing by {1} to maximize the value
  [key: `common.recommendation.type.${Recommendation['type']}`]: string; // Pricing
  [key: `common.recommendation.upgrade-to-type.${VariantTextKey}`]: string; // to upgrade type

  'common.recommendation.accepted.tag': string; // Accepted
  'common.recommendation.accepted.pricing-copy': string; // {0} pricing {1}
  'common.recommendation.accepted.amount-to-date': string; // Amount to date
  'common.recommendation.accepted.amount-finalized': string; // Total amount
  'common.recommendation.accepted.start-date': string; // Accepted: {0}
  'common.recommendation.accepted.days-elapsed-of-total': string; // {0} / {1} days
  'common.recommendation.accepted.days-elapsed-of-total.a11y': string; // {0} of {1} days elapsed

  'common.recommendation-carousel.header': string; // Top Recommendations,
  'common.recommendation-carousel.previous': string; // Previous,
  'common.recommendation-carousel.next': string; // Next,

  'common.recommendation.sort.aria-label': string; // Select a sorting option
  'common.recommendation.sort.by-revenue': string; // Revenue opportunity
  'common.recommendation.sort.by-uplift': string; // Uplift (%)
  'common.recommendation.sort.by-recent': string; // Recent
  'common.recommendation.sort.by-oldest': string; // Oldest

  'common.errors.no-results': string; // No Recommendations
  'common.errors.server-error': string; // Server Error
  'common.errors.connection-lost': string; // Connection Lost
  'common.errors.failed-chart': string; // We had trouble fetching this data

  'common.errors.server-error.text': string; // We had trouble fetching this data

  [key: `common.upgrade.type.${VariantTextKey}`]: string; // upgrade type

  [key: `common.currency.${Currency['code']}`]: string; // AUD

  'common.confirmation-dialog.title': string; // Confirm changes:
  'common.confirmation-dialog.copy': string; // Are you sure that you want these changes applied?
  'common.confirmation-dialog.accept': string; // Accept
  'common.confirmation-dialog.decline': string; // Decline

  'common.insights-card.acceptance-rate.series.label': string; // Acceptance rate
  'common.insights-card.acceptance-rate.series.rate': string; // Rate %
  'common.insights-card.acceptance-rate.series.offers': string; // Offers
  'common.insights-card.acceptance-rate.series.upgrades': string; // Upgrades
  'common.insights-card.average-upgraded-bid.label': string; // Average Upgraded Bid
  'common.insights-card.average-upgraded-bid.series': string; // Avg. upgraded bid
  'common.insights-card.percentage-change.mom': string; // MoM:
  'common.insights-card.percentage-change.yoy': string; // YoY:
  'common.insights-card.uncaptured.expired-seats': string; // {0} submitted seats expired
  'common.insights-card.missed-opportunity.expired-seats': string; // {0} availabe seats missed
  'common.insights-card.growth.pending-seats': string; // {0} submitted seats pending
  'common.insights-card.upcoming.no-offers': string; // {0} eligible flights with no offers
  'common.insights-card.performance.revenue.title': string; // Revenue in {0}
  'common.insights-card.performance.offers.title': string; // Offers in {0}
  'common.insights-card.performance.acceptance.title': string; // Acceptance rate
  'common.insights-card.performance.upgraded.title': string; // Upgraded passengers
  'common.insights-card.jarvis.uncaptured.title': string; // Uncaptured Revenue
  'common.insights-card.jarvis.missed.title': string; // Missed opportunity
  'common.insights-card.jarvis.growth.title': string; // Growth headroom
  'common.insights-card.jarvis.upcoming.title': string; // Upcoming flights with offers

  'common.dashboard.title': string; // Dashboard

  'common.reports.form.title': string; // Generate Report

  'common.reports.form.report-type.expired': string; // Expired
  'common.reports.form.report-type.flight': string; // Flight
  'common.reports.form.report-type.incremental': string; // Incremental
  'common.reports.form.report-type.offer': string; // Offer
  'common.reports.form.report-type.summary': string; // Summary
  'common.reports.form.report-type.upgraded': string; // Upgraded
  'common.reports.form.report-type.modifications': string; // Modifications
  'common.reports.form.report-type.refunded': string; // Refunded

  'common.reports.form.report-base.submission-date': string; // Submission date
  'common.reports.form.report-base.departure-date': string; // Departure date
  'common.reports.form.report-base.fulfillment-date': string; // Fulfillment date
  'common.reports.form.report-base.expiration-date': string; // Expiration date

  'common.reports.form.report-period.label': string; // Period
  'common.reports.form.report-type.label': string; // Report Type
  'common.reports.form.report-base.label': string; // Based On
  'common.reports.form.report-pii-check.label': string; // Include PII
  'common.reports.form.report-upgrade-type.label': string; // Upgrade Type
  'common.reports.form.report-flight.label': string; // Flight Number
  'common.reports.form.report-origin.label': string; // Origin
  'common.reports.form.report-destination.label': string; // Destination
  'common.reports.form.generate.button': string; // Generate
  'common.reports.form.reset.button': string; // Reset
  'common.reports.form.validation.error.select-option': string; // Select an option
  'common.reports.form.validation.error.invalid-format': string; // Invalid format

  'common.reports.form.submit.error.validation': string; // Fill the required fields
  'common.reports.form.submit.success': string; // Report is being generated

  'common.reports.past-reports.title': string; // Past Reports
  'common.reports.past-reports.empty': string; // No reports generated
  'common.reports.past-reports.column-title.report-type': string; // Report Type
  'common.reports.past-reports.column-title.pii': string; // PII
  'common.reports.past-reports.column-title.start-date': string; // Start Date
  'common.reports.past-reports.column-title.end-date': string; // End Date
  'common.reports.past-reports.column-title.upgrade-type': string; // Upgrade Type
  'common.reports.past-reports.column-title.status': string; // Status
  'common.reports.past-reports.column-title.generated-time': string; // Generated Time (UTC+0)
  'common.reports.past-reports.column-title.download': string; // Time Left To Download
  'common.reports.past-reports.cell-content.download': string; // Download
  'common.reports.past-reports.cell-content.download.failure': string; // Failed to download report
  'common.reports.past-reports.expanded-row-title.based-on': string; // Based On:
  'common.reports.past-reports.expanded-row-title.flight-number': string; // Flight Number:
  'common.reports.past-reports.expanded-row-title.origin': string; // Origin:
  'common.reports.past-reports.expanded-row-title.destination': string; // Destination:
  [key: `common.reports.past-reports.expanded-row.based-on.${string}`]: string; // Submission date, Departure date, Fulfillment date, Expiration date
  'common.reports.past-reports.status.complete': string; // Done
  'common.reports.past-reports.status.pending': string; // In Progress
  'common.reports.past-reports.status.error': string; // Error
  'common.reports.past-reports.hide-pii.true': string; // No
  'common.reports.past-reports.hide-pii.false': string; // Yes
  'common.reports.past-reports.upgrade-type.all': string; // ALL
  'common.reports.past-reports.download.expired': string; // Expired
  'common.reports.past-reports.download.timeout': string; // Timeout

  [key: `${AppName}.page.title.${string}`]: string; // AMI Lite
  [key: `${AppName}.page.description.${string}`]: string; // You are lost
}
