import { useRef, useEffect } from 'react';
import { useParams } from 'react-router';
import useAppConfig from 'common/states/config/useAppConfig';
import useSetParams from 'common/libs/utils/urls/useSetParams';
import getCurrentPartner from './getCurrentPartner';

/**
 * cross-checks the `pid` set in react-router and
 * updates it if it doesn't match the active partner
 *
 * note: does not cause a page refresh
 */
const useSoftNavigate = () => {
  const { pid: pidFromUrl } = useParams();
  const { setParams } = useSetParams().actions;
  const { partnerInfo: partnerInState, partners } = useAppConfig().states;
  const partnerInfo = getCurrentPartner(pidFromUrl, partners);

  const previousPidRef = useRef<string | undefined>(pidFromUrl);

  useEffect(() => {
    if (pidFromUrl !== partnerInState?.pid && previousPidRef.current !== partnerInState?.pid) {
      if (!pidFromUrl && partnerInfo?.pid) {
        setParams({ pid: partnerInfo.pid });
      } else if (pidFromUrl && partnerInState?.pid && partnerInState.pid !== pidFromUrl) {
        setParams({ pid: partnerInState.pid });
      }

      previousPidRef.current = partnerInState?.pid;
    }
  }, [pidFromUrl, partnerInfo?.pid, partnerInState?.pid, setParams]);
};

export default useSoftNavigate;
