export enum GoogleAnalyticsEventType {
  default = 'default',
  pageView = 'page_view',
  pageViewPartial = 'page_view_partial',
  click = 'click',
  performance = 'performance',
  error = 'error',
}

export enum GoogleAnalyticPage {
  login = 'Login Page',
  auth = 'Keycloak Auth Page',
  liteHome = 'Lite Home Page',
  liteRecommendations = 'Lite Recommendations Page',
  liteReports = 'Lite Reports Page',
  studioHome = 'Studio Dashboard Page',
  studioRecommendations = 'Studio Recommendations Page',
  studioReports = 'Studio Reports Page',
}

export enum GoogleAnalyticClick {
  login = 'log in',
  logout = 'log out',
  changePartner = 'change_partner',
}

export enum GoogleAnalyticsPerformance {
  partialPageLoad = 'partial page load',
  fullPageLoad = 'full page load',
}

export enum GoogleAnalyticsReporting {
  generateReport = 'generate_report',
  downloadReport = 'download_report',
}
