import { useEffect, type ReactNode } from 'react';
import { type DocumentNode, useQuery } from '@apollo/client';
import { useParams } from 'react-router';

import type { Configuration, PartnerInfo } from 'types/Configuration';

import useAppConfig from 'common/states/config/useAppConfig';

import GET_PARTNER_CONFIGURATION from './queries/GET_PARTNER_CONFIGURATION';
import GET_PARTNER_GROUP_FROM_TOKEN from './queries/GET_PARTNER_GROUP_FROM_TOKEN';
import SettingsInitializer from './SettingsInitializer';
import useSoftNavigate from './utils/useSoftNavigate';
import getCurrentPartner from './utils/getCurrentPartner';
import sortPartner from './utils/sortPartner';
import GET_PARTNER_PERMISSIONS from './queries/GET_PARTNER_PERMISSIONS';
import type { Permission } from 'types/Permissions';
import useCommmonEvents from 'common/libs/utils/analytics/useCommonEvents';

export interface PartnerConfigProviderProps {
  query?: DocumentNode;
  children: ReactNode;
}

interface PartnerGroupQueryResponse {
  getPartnerGroupFromToken: {
    partners: PartnerInfo[];
  };
}

interface PartnerPermissionsQueryParameter {
  partnerPid: string;
}

interface PartnerPermissionsQueryResponse {
  getPermissionByPartnerPid: {
    scopes: Permission[];
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface PartnerConfigQueryParameter extends Pick<PartnerInfo, 'pid'> {}

interface PartnerConfigQueryResponse {
  getApplicationConfiguration: Configuration;
}

export const PartnerConfigProvider = ({ children }: PartnerConfigProviderProps) => {
  const { pid: pidFromUrl } = useParams();
  const { publishError } = useCommmonEvents().actions;
  useSoftNavigate();

  const { setConfiguration, setPartners, setPartnerInfo, setPermissions } = useAppConfig().actions;

  const {
    loading: partnerGroupLoading,
    data: partnerGroupData,
    error: partnerGroupError,
  } = useQuery<PartnerGroupQueryResponse>(GET_PARTNER_GROUP_FROM_TOKEN);

  if (partnerGroupError) {
    publishError('GET_PARTNER_GROUP_FROM_TOKEN', partnerGroupError);
  }

  const partners = partnerGroupData?.getPartnerGroupFromToken.partners;
  const partnerInfo = getCurrentPartner(pidFromUrl, partners);

  const {
    loading: permissionsLoading,
    data: permissionsData,
    error: permissionsError,
  } = useQuery<PartnerPermissionsQueryResponse, PartnerPermissionsQueryParameter>(GET_PARTNER_PERMISSIONS, {
    variables: { partnerPid: partnerInfo?.pid ?? '' },
    skip: partnerGroupLoading || !partnerInfo,
  });

  if (permissionsError) {
    publishError('GET_PARTNER_PERMISSIONS', permissionsError);
  }

  const permissions = permissionsData?.getPermissionByPartnerPid.scopes;

  useEffect(() => {
    if (partners) setPartners(partners.toSorted(sortPartner));
    if (partnerInfo) setPartnerInfo(partnerInfo);
    if (permissions) setPermissions(permissions);
  }, [pidFromUrl, partners, partnerInfo, permissions]);

  const {
    loading,
    data: configData,
    error: configError,
  } = useQuery<PartnerConfigQueryResponse, PartnerConfigQueryParameter>(GET_PARTNER_CONFIGURATION, {
    variables: { pid: partnerInfo?.pid ?? '' },
    skip: partnerGroupLoading || permissionsLoading || !partnerInfo,
    context: { secure: false }, // <- custom context, will hit `/graphql` instead of `/secure/graphql`
  });

  if (configError) {
    publishError('GET_PARTNER_CONFIGURATION', configError);
  }

  const configuration = configData?.getApplicationConfiguration;

  useEffect(() => {
    if (configuration) setConfiguration({ ...configuration });
  }, [configuration]);

  if (partnerGroupLoading || permissionsLoading || loading) return <></>;

  if (!configuration || !partnerInfo) throw new Error('Missing app config !');

  return <SettingsInitializer {...partnerInfo}>{children}</SettingsInitializer>;
};

export default PartnerConfigProvider;
