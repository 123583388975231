import { makeVar, useReactiveVar } from '@apollo/client';
import Keycloak from 'keycloak-js';
import deepEqual from 'deep-equal';

import type { AuthenticatedUser } from 'types/AuthenticatedUser';
import type { IDPConfig } from 'common/configs/commonConfig';
import commonConfig, { DEFAULT_IDP_CONFIG } from 'common/configs/commonConfig';
import getInitials from './utils/getUserInitials';
import { getDomainIdentityProviderConfig } from 'common/configs/identityProviderConfig';

const keycloak = makeVar<Keycloak | undefined>(undefined);
const isInitialized = makeVar<boolean>(false);
const isAuthenticated = makeVar<boolean>(false);
const authenticatedUser = makeVar<AuthenticatedUser | undefined>(undefined);
const idpConfig = makeVar<IDPConfig>(DEFAULT_IDP_CONFIG);

export const AUTH_TOKEN = 'AUTH_TOKEN';
const FORCE_REFRESH_MIN_VALIDITY = -1;

const initialize = async () => {
  try {
    if (!isInitialized()) {
      const kcClient = new Keycloak(commonConfig.keycloak);
      kcClient.onTokenExpired = () => {
        logout();
      };
      await kcClient?.init({
        onLoad: 'check-sso',
      });
      setKeycloak(kcClient);
      setInitialized(true);
      if (kcClient?.authenticated) {
        initAuthenticatedUser();
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- intentional
  } catch (error) {
    throw new Error('useAuthentication.init: Failed to initialize the authentication service');
  }
};

const login = async ({ email }: { email: string }) => {
  try {
    if (!isInitialized()) {
      await initialize();
    }
    const kcClient = keycloak();
    setIDPConfig(getDomainIdentityProviderConfig(email));
    const loginHint = email;
    await kcClient?.login({ idpHint: idpConfig().idpHint, loginHint });
    if (kcClient?.authenticated) {
      initAuthenticatedUser();
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- intentional
  } catch (error) {
    throw new Error('useAuthentication.login: Failed to authenticate user');
  }
};

async function initAuthenticatedUser() {
  try {
    const currentUser: AuthenticatedUser | undefined = getCurrentUser();
    setAuthenticatedUser(currentUser);
    setIsAuthenticated(true);
    if (keycloak()?.token) {
      window.localStorage.setItem(AUTH_TOKEN, keycloak()?.token ?? '');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- intentional
  } catch (error) {
    throw new Error('useAuthentication.initAuthenticatedUser: Failed to initialize authenticated user');
  }
}

async function logout() {
  try {
    await keycloak()?.logout();
    resetAuthenticatedUser();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- intentional
  } catch (error) {
    throw new Error('useAuthentication.logout: Failed to logout user');
  }
}

async function updateToken() {
  try {
    await keycloak()?.updateToken(FORCE_REFRESH_MIN_VALIDITY);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars -- intentional
  } catch (error) {
    // logout user & re-direct to the login page because the KC session has expired
    keycloak()?.logout();
  }
}

const setKeycloak = (newInstance: Keycloak | undefined) => {
  const currentConfiguration = keycloak();
  if (!deepEqual(currentConfiguration, newInstance)) {
    keycloak(newInstance);
  }
};

const setInitialized = (newValue: boolean) => {
  const currentIsInitialized = isInitialized();
  if (currentIsInitialized !== newValue) {
    isInitialized(newValue);
  }
};

const resetAuthenticatedUser = () => {
  setAuthenticatedUser(undefined);
};

const setIsAuthenticated = (newValue: boolean) => {
  const currentIsAuthenticated = isAuthenticated();
  if (currentIsAuthenticated !== newValue) {
    isAuthenticated(newValue);
  }
};
const setIDPConfig = (newValue: IDPConfig) => {
  const currentConfig = idpConfig();
  if (currentConfig !== newValue) {
    idpConfig(newValue);
  }
};

const setAuthenticatedUser = (newUser: AuthenticatedUser | undefined) => {
  const currentConfiguration = authenticatedUser();
  if (!deepEqual(currentConfiguration, newUser)) {
    authenticatedUser(newUser);
  }
  setIsAuthenticated(Boolean(newUser));
};

const getCurrentUser = (): AuthenticatedUser | undefined => {
  const kc = keycloak();
  if (!kc?.tokenParsed) return undefined;

  const { token, tokenParsed } = kc;

  return {
    id: tokenParsed.sub,
    email: tokenParsed.email,
    jwtToken: token,
    fullName: `${tokenParsed.given_name} ${tokenParsed.family_name}`,
    firstName: tokenParsed.given_name,
    lastName: tokenParsed.family_name,
  };
};

const useAuthentication = () => {
  const kc = useReactiveVar(keycloak);
  const initialized = useReactiveVar(isInitialized);
  const authenticated = useReactiveVar(isAuthenticated);
  const user = useReactiveVar(authenticatedUser);
  const authProviderConfig = useReactiveVar(idpConfig);

  return {
    actions: {
      initialize,
      login,
      updateToken,
      logout,
    },
    states: {
      loading: !kc,
      isInitialized: initialized,
      isAuthenticated: authenticated,
      authenticatedUser: user,
      idpConfig: authProviderConfig,
    },
    selectors: {
      getJWTToken: () => kc?.token,
      getUserInitials: (companyCode?: string, rtl?: boolean) => getInitials(user?.fullName, companyCode, rtl),
    },
  };
};

export default useAuthentication;
