import isLocalDevelopment from '../../../libs/utils/environments/isLocalDevelopment';

const alreadyPrintedTextkeys: string[] = [];

export const unsafeClearCache = () => {
  while (alreadyPrintedTextkeys.length > 0) {
    alreadyPrintedTextkeys.pop();
  }
};

const printMissingTextkey = (message: string, code: string) => {
  if (isLocalDevelopment()) {
    // we want to avoid spam on locale so we display the missing textkey only once
    if (!alreadyPrintedTextkeys.includes(code)) {
      console.log(message, code);
      alreadyPrintedTextkeys.push(code);
    }
  } else {
    console.error(message, code);
  }
};

export default printMissingTextkey;
