import commonConfig, { DEFAULT_IDP_CONFIG } from 'common/configs/commonConfig';
import { getEmailDomain } from 'common/utils/emailDomain';

export const getDomainIdentityProviderConfig = (email: string | null) => {
  if (email) {
    const emailDomain = getEmailDomain(email);
    if (emailDomain && emailDomain in commonConfig.keycloak.idpConfigByDomain) {
      return commonConfig.keycloak.idpConfigByDomain[emailDomain];
    }
  }
  return DEFAULT_IDP_CONFIG;
};
