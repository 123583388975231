const publish = (eventName: string, payload: object) => {
  window.dataLayer = window.dataLayer ?? [];
  window.dataLayer.push({ event: eventName, ...payload, environment: window.plusgrade?.env });
};

const usePublishGoogleAnalyticsEventMutation = () => {
  return {
    actions: {
      publish,
    },
  };
};

export default usePublishGoogleAnalyticsEventMutation;
